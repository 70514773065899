import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private keyCloakService: KeycloakService) {}

  logout() {
    const path = window.location.hash.substring(1);
    const urlBase = window.location.href.slice(0, -path.length);
    this.keyCloakService.logout(urlBase);
  }
}
