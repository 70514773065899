import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { RequestValidarUsuarioTermoConsentimento } from '../tos/request-validar-usuario-termo-consentimento';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsuarioTermoConsentimentoService {
  private cache = {};
  private KEY = 'VALIDAR_TERMO';


  constructor(private httpClient: HttpClient) { }

  getValidarTermoConsentimento(): Observable<RequestValidarUsuarioTermoConsentimento> {
    return this.cache[this.KEY]
    ? of(this.cache[this.KEY])
    : this.requestValidarTermoConsentimento()
  }

  downloadTermoConsentimento(): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', '*/*');
    return this.httpClient.get<any>(`/v1/usuario-termo-consentimento/download`, {headers: headers, responseType: 'blob' as 'json'}).pipe(
      map(r => new Blob([r], {type: 'application/pdf'}))
    );
  }

  acordarTermoConsentimento() {
    this.cleanCache();
    return this.httpClient.put<any>(`/v1/usuario-termo-consentimento/de-acordo`, {})
  }

  private requestValidarTermoConsentimento(): Observable<RequestValidarUsuarioTermoConsentimento> {
    return this.httpClient.get<RequestValidarUsuarioTermoConsentimento>(`/v1/usuario-termo-consentimento/validar`).pipe(
      tap(response => this.cache[this.KEY] = response)
    );
  }

  private cleanCache() {
    this.cache = {};
  }
}
