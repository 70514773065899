import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SidebarService } from 'src/app/layout/sidebar/sidebar.service';
import { UsuarioTermoConsentimentoService } from 'src/app/shared/api-services/usuario-termo-consentimento.service';
import { NotificationType } from 'src/app/shared/enums/notification-type.enum';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { RouterService } from 'src/app/shared/services/router-service.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-termo-consentimento',
  templateUrl: './termo-consentimento.component.html',
  styleUrls: ['./termo-consentimento.component.scss']
})
export class TermoConsentimentoComponent implements OnInit {
  loading: boolean = false;
  loadingPdf: boolean = false;
  termo: string;

  constructor(
    private termoConsentimentoService: UsuarioTermoConsentimentoService,
    private sidebarService: SidebarService,
    private routerService:RouterService,
    private toastService: ToastService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.verificarNecessidadeTermo();
  }

  verificarNecessidadeTermo() {
    this.loadingPdf = true;
    this.termoConsentimentoService.getValidarTermoConsentimento().subscribe(res => {
      if (res.flExibirTermo) {
        this.getTermo();
      } else {
        this.loadingPdf = false;
        this.notificationService.notify(this.translateService.instant('termoConsentimento.naoPrecisaAceitar'), NotificationType.INFO, null,
        () => this.routerService.toDashboard())
      }
    }, err => {
      this.loadingPdf = false;
      throw err;
    })
  }

  getTermo() {
    this.loadingPdf = true;
    this.termoConsentimentoService.downloadTermoConsentimento().subscribe(r => {
      this.loadingPdf = false;
      this.termo = URL.createObjectURL(r);
    }, err => {
      this.loadingPdf = false
      throw err;
    })
  }

  concordarTermo() {
    this.loading = true;
    this.termoConsentimentoService.acordarTermoConsentimento().subscribe(r => {
      this.loading = false;
      this.routerService.toDashboard();
      this.toastService.notifyWithTranslateSuccess('atualizadoSucesso')
    }, err => {
      this.loading = false;
      throw err;
    })
  }

  discordarTermo() {
    this.sidebarService.logout()
  }

}
