<div class="conteudoPageCrud">
  <app-titulo-edicao-crud [titulo]="'termoConsentimento.textoTermo' | translate"></app-titulo-edicao-crud>

  <app-loader [loading]="loadingPdf" />
  @if (termo) {
  <div class="pdf-wrapper">
    <pdf-viewer [src]="termo" [original-size]="true" [fit-to-page]="true"></pdf-viewer>
  </div>
  <div class="footer">
    <button
      (click)="concordarTermo()"
      type="button"
      [label]="'termoConsentimento.concordar' | translate"
      pButton
      class="botao"
      [disabled]="loading"
      [loading]="loading"
    ></button>
    <button [disabled]="loading" (click)="discordarTermo()" type="button" class="botao-branco" translate>
      termoConsentimento.discordar
    </button>
  </div>
  }
</div>
