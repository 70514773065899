import { Injectable } from "@angular/core";
import { AuthService } from "src/app/shared/services/auth.service";

@Injectable({
  providedIn: "root",
})
export class SidebarService {
  sidebarOpen = false;

  constructor(private authService: AuthService) {}

  openSidebar() {
    this.sidebarOpen = true;
    this.travarScroll(this.sidebarOpen);
  }

  closeSidebar() {
    this.sidebarOpen = false;
    this.travarScroll(this.sidebarOpen);
  }

  toggleSidebar() {
    this.sidebarOpen = !this.sidebarOpen;
    this.travarScroll(this.sidebarOpen);
  }

  logout() {
    this.authService.logout();
    this.closeSidebar();
  }

  travarScroll(open: boolean) {
    const body = document.getElementsByTagName("body")[0];
    const html = document.getElementsByTagName("html")[0];
    if (open) {
      body.style.overflow = "hidden";
      html.style.overflow = "hidden";
    } else {
      body.style.overflow = null;
      html.style.overflow = null;
    }
  }
}
